import React from "react";

import { StaticImage } from "gatsby-plugin-image";

const YourConsumptionAnimation = () => {
  return (
    <div className="h-[420px] w-[280px] sm:w-[470px] sm:h-[480px] ">
      <div className="shadow-3xl bg-white rounded-md h-full overflow-hidden relative ">
        <div className="w-full flex flex-col px-4 py-4">
          <div className="w-full sm:pt-8 pb-2 sm:pb-4 px-2 bg-white rounded-md ring-1 ring-gray-100">
            <StaticImage
              src={"../../images/animations/your_consumption.png"}
              placeholder="blurred"
              layout="constrained"
              loading="eager"
              alt="graph of consumption data"
            />
          </div>
        </div>
      </div>
    </div >
  );
};

export default YourConsumptionAnimation;
