class IntersectionWatcher {
  constructor(threshold, entryCallback) {
    const options = { root: null, threshold: threshold };
    const callback = this.callbackWrapper(entryCallback);
    this.observer = new IntersectionObserver(callback, options);
  }

  callbackWrapper(entryCallback) {
    return function callback(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entryCallback();
          observer.unobserve(entry.target);
        }
      });
    };
  }

  observe(entryToObserveId) {
    const entryToObserve = document.getElementById(entryToObserveId);
    this.observer.observe(entryToObserve);
  }
}

export default IntersectionWatcher;
