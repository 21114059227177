import React, { useState } from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";

import { FaBolt } from "@react-icons/all-files/fa/FaBolt";


const AppSignupLink = () => {
  return (
    <div className="w-full sm:max-w-[24rem]">
      <p className="text-xs text-white mb-3 font-light">
        <FormattedMessage id="app_signup_text" />
      </p>
      <div className="flex w-full">
        <a
          id="submit-btn"
          className="app-signup-button text-sm px-1 py-3 rounded-md bg-[#5866BF] text-white hover:bg-[#7664B8] w-6/12 flex justify-center items-center"
          href="https://app.reel.energy/users/register/"
        >
          <FormattedMessage id="app_signup_btn" />{" "}
          <FaBolt className="ml-1" />
        </a>
      </div>
    </div >
  );
};

export default AppSignupLink;