import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";

const CreateAccountAnimation = () => {
  return (
    <div className="w-full relative">
      <div className="absolute top-0 left-0 bg-white px-3 py-3 shadow-3xl w-72 sm:w-80 lg:w-96 rounded-md z-10">
        <div className="w-full h-full rounded-md relative">
          <StaticImage
            src={"../../images/animations/create_account.png"}
            placeholder="blurred"
            layout="constrained"
            loading="lazy"
            alt="create account page"
            className="z-10"
          />
        </div>
      </div>
    </div>
  );
};

export default CreateAccountAnimation;
