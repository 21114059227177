import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";

const DefinePPAAnimation = () => {
  return (
    <div className="w-full relative">
      <div className="absolute top-0 left-0 bg-white px-3 py-3 shadow-3xl w-72 sm:w-80 lg:w-96 rounded-md z-10">
        <div className="py-2">
          <p className="text-sm text-gray-700 font-regular">
            <FormattedMessage id="define_ppa_title" />
          </p>
          <p className="text-xs text-gray-500 font-light py-2">
            <FormattedMessage id="define_ppa_subtitle" />
          </p>
        </div>
        <div className="w-full h-full relative">
          <StaticImage
            src={"../../images/animations/ppa_over_time.png"}
            placeholder="blurred"
            layout="constrained"
            loading="lazy"
            alt="yearly PPA graph"
            className="z-10"
          />
        </div>
        <div className="w-full h-full  relative">
          <StaticImage
            src={"../../images/animations/hourly_consumption.png"}
            placeholder="blurred"
            layout="constrained"
            loading="lazy"
            alt="hourly consumption graph"
            className="z-10"
          />
        </div>
      </div>
    </div>
  );
};

export default DefinePPAAnimation;
