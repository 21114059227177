import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";

import AppSignupLink from "../helpers/appSignupLink";
import ProfilesAnimation from "../animations/profiles";
import YourConsumptionAnimation from "../animations/yourConsumption";

const Hero = () => {
  return (
    <section className="index-hero">
      <div className="w-screen pt-28 md:pt-40 sm:pb-32 md:pb-0 bg-[#7587ff] bg-cover">

        <div className="flex justify-center w-screen pb-8 items-center ">
          <div className="grid grid-cols-1 sm:grid-cols-2 w-full lg:w-1220">
            <div className="flex flex-col px-4 md:pt-6">
              <h1
                className="text-white text-2xl sm:text-4xl md:text-7xl animated-gradient-text-bg font-semibold tracking-tighter pb-8"
                id="hero-heading"
              >
                <FormattedMessage id="ppa_simulator_hero_heading" />
              </h1>
              <h2
                className="text-base font-light text-white pb-8 md:pr-12"
                style={{ lineHeight: 1.8 }}
              >
                <FormattedMessage id="ppa_simulator_hero_sub_heading" />
              </h2>
              <div className="min-h-[150px]">
                <AppSignupLink></AppSignupLink>
              </div>
            </div>
            <div className="hidden sm:flex justify-center relative pt-8">
              <div className="z-30 absolute top-8 left-[300px]">
                <ProfilesAnimation></ProfilesAnimation>
              </div>
              <div className="absolute z-20 -top-8 left-[150px]">
                <YourConsumptionAnimation></YourConsumptionAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
