import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import Hero from "../components/yearly-baseload-ppa-simulator/hero";
import HowItWorks from "../components/yearly-baseload-ppa-simulator/howItWorks";

const PPASimulatorPage = () => {
  return (
    <Layout isLight={true}>
      <Seo
        title="PPA Simulator"
        description="Simulate PPA Contracts"
      />

      <Hero></Hero>

      <HowItWorks></HowItWorks>

    </Layout>
  );
};
export default PPASimulatorPage;
