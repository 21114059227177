import React from "react";

import { StaticImage } from "gatsby-plugin-image";

const AnalyzeDataAnimation = () => {
  return (
    <div className="w-full relative">
      <div className="absolute top-0 left-0 bg-white px-3 py-3 shadow-3xl w-72 sm:w-80 lg:w-96 rounded-md z-10">
        <div className="w-full h-full rounded-md relative">
          <StaticImage
            src={"../../images/animations/consumption.png"}
            placeholder="blurred"
            layout="constrained"
            loading="lazy"
            alt="graph of consumption"
            className="z-10"
          />
        </div>
      </div>
      <div className="absolute top-6 -right-0 lg:-right-24 w-52 lg:w-60 z-20 top-24">
        <div className="w-full h-full bg-white shadow-3xl rounded-md flex flex-col px-3 py-3">
          <div className="w-full rounded-md rounded-md bg-white border-b border-gray-200">
            <div className="w-full h-full rounded-md relative">
              <StaticImage
                src={"../../images/animations/schedule_meeting.png"}
                placeholder="blurred"
                layout="constrained"
                loading="lazy"
                alt="meeting scheduler"
                className="z-10"
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyzeDataAnimation;
