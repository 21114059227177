import React from "react";

import { StaticImage } from "gatsby-plugin-image";

const EvaluatePPAAnimation = () => {
  return (
    <div className="w-full relative">
      <div className="bg-white px-3 py-3 shadow-3xl w-72 sm:w-80 lg:w-96 rounded-md z-10">
        <div className="w-full h-full relative">
          <StaticImage
            src={"../../images/animations/ppa_numbers.png"}
            placeholder="blurred"
            layout="constrained"
            loading="lazy"
            alt="PPA evaluation numbers"
            className="z-10"
          />
        </div>
        <div className="w-full h-full relative">
          <StaticImage
            src={"../../images/animations/ppa_graph.png"}
            placeholder="blurred"
            layout="constrained"
            loading="lazy"
            alt="daily graph of PPA"
            className="z-10"
          />
        </div>
      </div>
    </div>
  );
};

export default EvaluatePPAAnimation;
